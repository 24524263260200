import React from "react"
import { Link } from "gatsby"
import { withTranslation } from "react-i18next"

const CompanyMobileItem = props => {
  const { t } = props
  return (
    <ul className={`sub-menu ${props.show}`}>
      <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/company">
          {t("src.pages.footer.company.title")}
        </Link>
      </li>
      {/* <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/>{DATA[14].title}</Link>
      </li> */}
      {/* <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/company/team">{DATA[15].title}</Link>
      </li>
      <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/company/careers">{DATA[11].title}</Link>
      </li> */}
      <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/company/contact">
          {t("src.pages.footer.company.about")}
        </Link>
      </li>
      {/* <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/company/blog">{DATA[21].title}</Link>
      </li> */}
      {/* <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/company/news">{DATA[7].title}</Link>
      </li> */}
      {/* <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/company/insights">{DATA[6].title}</Link>
      </li> */}
    </ul>
  )
}
export default withTranslation()(CompanyMobileItem)
