import React, { useState } from "react"
import { withTranslation } from "react-i18next"

const LanguageItem = props => {
  const { i18n, t } = props
  const [lang, setlang] = useState(
    i18n.language === "en" ? "ENGLISH" : "TIẾNG VIỆT"
  )
  const [open, setopen] = useState(false)
  const openDropdown = () => {
    setopen(!open)
  }
  function Changelanguage(select) {
    if (select === "en") {
      setlang("English")
    } else {
      setlang("Tiếng Việt")
    }
    i18n.changeLanguage(select)
  }
  const keydown = () => {}

  return (
    <div className={`languageMobile ${open ? "open" : ""} `}>
      <div
        onBlur={openDropdown}
        role="button"
        tabIndex={0}
        onClick={openDropdown}
        onKeyDown={keydown}
        className="current-language"
        style={{ display: "flex", alignItems: "center" }}
      >
        <p>{lang}</p>
      </div>
      <ul className="dropdown-menu">
        {i18n.language === "en" ? (
          <li>
            <button type="button" onMouseDown={Changelanguage.bind(this, "vi")}>
              {t("src.pages.sliderbar.vietnam")}
            </button>
          </li>
        ) : (
          <li>
            <button type="button" onMouseDown={Changelanguage.bind(this, "en")}>
              {t("src.pages.sliderbar.english")}
            </button>
          </li>
        )}
      </ul>
    </div>
  )
}

export default withTranslation()(LanguageItem)
