import React, { useState } from "react"
import { Link } from "gatsby"
import CompanyMobileItem from "../../header/navBarItemMobile/componentsMobile/companyMobile.component"
// import IndustriesMobileItem from "../../header/navBarItemMobile/componentsMobile/industriesMobile.component"
import ServicesMobileItem from "../../header/navBarItemMobile/componentsMobile/servicesMobile.component"
import SolutionMobileItem from "../../header/navBarItemMobile/componentsMobile/solutionsMobile.component"
import "./footerMobile.scss"
import { withTranslation } from "react-i18next"

const FooterMobile = props => {
  const { t } = props
  const [toggleClass, setToggleClass] = useState("")
  const addToggleClassServices = e => {
    e.preventDefault()
    setToggleClass(toggleClass === "Services" ? "" : "Services")
  }
  const addToggleClassSolutions = e => {
    e.preventDefault()
    setToggleClass(toggleClass === "Industries" ? "" : "Industries")
  }
  const addToggleClassCompany = e => {
    e.preventDefault()
    setToggleClass(toggleClass === "Company" ? "" : "Company")
  }
  let resetToggleClass = false
  if (props.reset) {
    resetToggleClass = true
  }
  return (
    <>
      <nav className="footerMobile">
        <div
          className="dropdownMBFooter"
          style={{ cursor: "pointer" }}
          onClick={addToggleClassServices}
        >
          <Link
            className={`nav-itemMB ${
              toggleClass === "Services" && !resetToggleClass ? "active" : ""
            }`}
            activeclassname="active-nav-itemMB"
            to="/services"
            onClick={addToggleClassServices}
          >
            {t("src.pages.footer.services.title")}
          </Link>
          <ServicesMobileItem
            show={
              toggleClass === "Services" && !resetToggleClass ? "active" : ""
            }
          />
        </div>
        <div
          className="dropdownMBFooter"
          style={{ cursor: "pointer" }}
          onClick={addToggleClassSolutions}
        >
          <Link
            className={`nav-itemMB ${
              toggleClass === "Industries" && !resetToggleClass ? "active" : ""
            }`}
            activeclassname="active-nav-itemMB"
            to="/solutions"
            onClick={addToggleClassSolutions}
          >
            {t("src.pages.footer.solutions.title")}
          </Link>
          <SolutionMobileItem
            show={
              toggleClass === "Industries" && !resetToggleClass ? "active" : ""
            }
          />
        </div>
        <div
          className="dropdownMBFooter dropdownMBFooterCompany"
          style={{ cursor: "pointer" }}
          onClick={addToggleClassCompany}
        >
          <Link
            className={`nav-itemMB ${
              toggleClass === "Company" && !resetToggleClass ? "active" : ""
            }`}
            activeclassname="active-nav-itemMB"
            to="/company"
            onClick={addToggleClassCompany}
          >
            {t("src.pages.footer.company.title")}
          </Link>
          <CompanyMobileItem
            show={
              toggleClass === "Company" && !resetToggleClass ? "active" : ""
            }
          />
        </div>
      </nav>
    </>
  )
}
export default withTranslation()(FooterMobile)
