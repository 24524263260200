import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import pic1 from "./../../../assets/img/media/header/3.1.png"
import pic2 from "./../../../assets/img/media/header/3.2.png"
import pic4 from "./../../../assets/img/media/header/1.4.png"
// import pic5 from "./../../../assets/img/media/header/1.5.jpg"
import { Link } from "gatsby"

import "./navBarItem.scss"
const solutionItem = props => {
  const { t } = props
  const [isHoverSolution, setHoverSolution] = useState("")
  let classHover = ["coverDropdown", ""] //in-view

  let classHover2 = ["dropdown-content", ""]
  if (props.isHoverSolution === "Solution") {
    classHover = ["coverDropdown", "in-view"]
    classHover2 = ["dropdown-content", "in-view"]
  }

  const onMouseEnterWebinar = () => {
    setHoverSolution("Webinar")
  }
  const onMouseLeaveWebinar = () => {
    setHoverSolution("")
  }

  // const onMouseEnterRetails = () => {
  //   setHoverSolution("Retails")
  // }
  // const onMouseLeaveRetails = () => {
  //   setHoverSolution("")
  // }
  const onMouseEnterSmarthome = () => {
    setHoverSolution("Smarthome")
  }
  const onMouseLeaveSmarthome = () => {
    setHoverSolution("")
  }
  const onMouseEnterDigital = () => {
    setHoverSolution("Digital")
  }
  const onMouseLeaveDigital = () => {
    setHoverSolution("")
  }
  const onMouseEnterSpirometer = () => {
    setHoverSolution("Spirometer")
  }
  const onMouseLeaveSpirometer = () => {
    setHoverSolution("")
  }
  return (
    <div
      className={classHover.join(" ")}
      onMouseEnter={props.enter}
      onMouseLeave={props.leave}
    >
      <div className="container">
        <div className={classHover2.join(" ")}>
          <div className="coverElm">
            <div className="article-container">
              <div className="article__item">
                <div className="article__content">
                  <Link to="/solutions" className="article__link">
                    <img
                      src={
                        isHoverSolution === "Webinar"
                          ? pic1
                          : isHoverSolution === "Pharma"
                          ? pic2
                          : // isHoverSolution === "Internet"
                            //   ? pic1
                            //   : isHoverSolution === "Blockchain"
                            //   ? pic2
                            //   : isHoverSolution === "Clound"
                            //   ? pic3
                            //   : isHoverSolution === "Artificial"
                            //   ? pic4
                            // : pic1
                            pic4
                      }
                      alt="solutions"
                      className="article__img"
                    />
                    <p className="article__description">
                      {/* {isHoverSolution === "Internet"
                        ? props.data[0].content
                        : isHoverSolution === "Blockchain"
                        ? props.data[1].content
                        : isHoverSolution === "Clound"
                        ? props.data[19].content
                        : isHoverSolution === "Artificial"
                        ? props.data[20].content
                        : props.data[0].content} */}
                      {props.data[0].content}
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="ddElm3">
              <div className="headerSubTitle">
                <p>{t("src.pages.footer.solutions.title")}</p>
              </div>
              <div className="headerElm">
                <div className="headerElm-container">
                  <div className="headerElm-left">
                    {/* <a
                      href="https://mdconference.acexis.com/"
                      target="_blank"
                      onMouseEnter={onMouseEnterWebinar}
                      onMouseLeave={onMouseLeaveWebinar}
                    >
                      {t("src.pages.footer.solutions.webinar")}
                    </a> */}

                    <Link
                      to="/solutions/pharma"
                      onMouseEnter={onMouseEnterSmarthome}
                      onMouseLeave={onMouseLeaveSmarthome}
                    >
                      {t("src.pages.footer.solutions.pharma")}
                    </Link>
                    {/* <Link
                      to="/solutions/smarthome"
                      onMouseEnter={onMouseEnterSmarthome}
                      onMouseLeave={onMouseLeaveSmarthome}
                    >
                      {t("src.pages.footer.solutions.smartHome")}
                    </Link>
                    <Link
                      to="/solutions/digitalhealth"
                      onMouseEnter={onMouseEnterDigital}
                      onMouseLeave={onMouseLeaveDigital}
                    >
                      {t("src.pages.footer.solutions.digital")}
                    </Link> */}
                  </div> 
                  <div className="headerElm-right">
                    {/* <Link
                      to="/solutions/spirometer"
                      onMouseEnter={onMouseEnterSpirometer}
                      onMouseLeave={onMouseLeaveSpirometer}
                    >
                      {t("src.pages.footer.solutions.spirometer")}
                    </Link> */}
                    <Link
                      to="/solutions/eventapp"
                      onMouseEnter={onMouseEnterDigital}
                      onMouseLeave={onMouseLeaveDigital}
                    >
                      {t("src.pages.footer.solutions.eventApp")}
                    </Link>
                    <Link
                      to="/solutions/erp"
                      onMouseEnter={onMouseEnterDigital}
                      onMouseLeave={onMouseLeaveDigital}
                    >
                      {t("src.pages.footer.solutions.erp")}
                    </Link>
                    {/* <Link
                  to="/solutions/retail-ecommerce"
                  onMouseEnter={onMouseEnterRetails}
                  onMouseLeave={onMouseLeaveRetails}
                >
                  {props.data[13].title}
                </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default withTranslation()(solutionItem)
