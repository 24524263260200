import React from "react"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

import logo1 from "./../../../assets/img/media/stakeholders/logo1.png"
import logo2 from "./../../../assets/img/media/stakeholders/logo2.png"
import logo3 from "./../../../assets/img/media/stakeholders/logo3.png"
import logo4 from "./../../../assets/img/media/stakeholders/logo4.png"
import logo5 from "./../../../assets/img/media/stakeholders/logo5.png"
import logo6 from "./../../../assets/img/media/stakeholders/logo6.png"
import logo7 from "./../../../assets/img/media/stakeholders/logo7.png"
import logo8 from "./../../../assets/img/media/stakeholders/logo8.png"
import logo9 from "./../../../assets/img/media/stakeholders/logo9.png"
import logo10 from "./../../../assets/img/media/stakeholders/logo10.png"
import logo11 from "./../../../assets/img/media/stakeholders/logo11.png"
import logo12 from "./../../../assets/img/media/stakeholders/logo12.png"

const AboutLogo = props => {
  const opptions = {
    autoPlay: true,
    autoPlayInterval: 5000,
    buttonsDisabled: true,
    responsive: {
      0: {
        items: 2
      },
      768: {
        items: 4
      },
      1024: {
        items: 4
      },
      1920: {
        items: 4
      } // number of elements in the slide
    },
    stagePadding: {
      paddingLeft: 0, // in pixels
      paddingRight: 0
    },
    mouseDragEnabled: true,
    fadeOutAnimation: true
  }

  return (
    <AliceCarousel {...opptions}>
      <div className="coverAbout">
        <img alt="img" className="editImgAbout" src={logo1}></img>
        <img alt="img" className="editImgAbout" src={logo11}></img>
      </div>
      <div className="coverAbout">
        <img alt="img" className="editImgAbout" src={logo3}></img>
        <img alt="img" className="editImgAbout" src={logo4}></img>
      </div>
      <div className="coverAbout">
        <img alt="img" className="editImgAbout" src={logo5}></img>
        <img alt="img" className="editImgAbout" src={logo2}></img>
      </div>
      <div className="coverAbout">
        <img alt="img" className="editImgAbout" src={logo7}></img>
        <img alt="img" className="editImgAbout" src={logo9}></img>
      </div>
    </AliceCarousel>
  )
}

export default AboutLogo
