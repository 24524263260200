import React from "react"
import { Link } from "gatsby"
import { withTranslation } from "react-i18next"

const SolutionsMobileItem = props => {
  const { t } = props
  return (
    <ul className={`sub-menu ${props.show}`}>
      <li className={`menu-item ${props.show}`}>
        <Link to="/solutions" className="mobileA">
          {t("src.pages.footer.solutions.title")}
        </Link>
      </li>
      {/* <li className={`menu-item ${props.show}`}>
        //<Link to="/" className="mobileA">
        //  Webinar
        //</Link>
        <a
          className="mobileA"
          href="https://mdconference.acexis.com/"
          target="_blank"
        >
          {t("src.pages.footer.solutions.webinar")}
        </a>
      </li> */}
      <li className={`menu-item ${props.show}`}>
        <Link to="/solutions/pharma" className="mobileA">
          {t("src.pages.footer.solutions.pharma")}
        </Link>
      </li>
      {/* <li className={`menu-item ${props.show}`}>
        <Link to="/solutions/smarthome" className="mobileA">
          {t("src.pages.footer.solutions.smartHome")}
        </Link>
      </li>
      <li className={`menu-item ${props.show}`}>
        <Link to="/solutions/digitalhealth" className="mobileA">
          {t("src.pages.footer.solutions.digital")}
        </Link>
      </li>
      <li className={`menu-item ${props.show}`}>
        <Link to="/solutions/spirometer" className="mobileA">
          {t("src.pages.footer.solutions.spirometer")}
        </Link>
      </li> */}
      <li className={`menu-item ${props.show}`}>
        <Link to="/solutions/eventapp" className="mobileA">
          {t("src.pages.footer.solutions.eventApp")}
        </Link>
      </li>
      <li className={`menu-item ${props.show}`}>
        <Link to="/solutions/erp" className="mobileA">
          {t("src.pages.footer.solutions.erp")}
        </Link>
      </li>
    </ul>
  )
}

export default withTranslation()(SolutionsMobileItem)
