import React from "react"
import logo from "./../../../assets/img/media/Sunny.png"
import DrawerToggle from "../../UI/SideDrawer/DrawerToggle/DrawerToggle.component"
import NavbarItem from "./../navBarItem/navbarItem.component"
import { Link } from "gatsby"
// import GetInTouchItem from "./getintouch.components"
import LanguageItem from "./language.component"
const navbaritems = props => {
  const { displayHeader } = props
  let attachClasses3 = ["logo", ""]
  
  if (displayHeader && props.scroll) {
    attachClasses3 = ["logo", "scroll"]
  } else {
    if (props.scroll) {
      attachClasses3 = ["logo", "scroll"]
    }
    if (displayHeader) {
      attachClasses3 = ["logo", "display-header"]
    }
  }
 
  let classCoverHeader = ["coverHeader", ""]
  if (props.scroll) {
    classCoverHeader = ["coverHeader", "in-view"]
  }
  let activelogo = ""
  if (props.isHover) {
    activelogo = "activeLogo"
  }
  return (
    <div className="nav container">
      <div className={classCoverHeader.join(" ")}>
        <div className={`classLogo ${props.toggleClass}`}>
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className={`${attachClasses3.join(" ")} ${
                props.toggleClass
              } ${activelogo}`}
            />
          </Link>
        </div>

        <div className={`navbarItem ${props.toggleClass}`}>
          <nav className="fixed-nav-bar">
            <div className={`top-nav ${props.toggleClass}`}>
              <div className={`cover-nav-item ${props.animation}`}>
                <DrawerToggle
                  clicked={props.drawerToggleClicked}
                  toggleClass={props.toggleClasss}
                />
                <NavbarItem
                  displayHeader={displayHeader}
                  scroll={props.scroll}
                  isHover={props.isHover}
                  onMouseEnterHandlerClient={props.onMouseEnterHandlerClient}
                  onMouseEnterHandlerCompany={props.onMouseEnterHandlerCompany}
                  // onMouseEnterHandlerIndustries={
                  //   props.onMouseEnterHandlerIndustries
                  // }
                  onMouseEnterHandlerService={props.onMouseEnterHandlerService}
                  onMouseEnterHandlerSolution={
                    props.onMouseEnterHandlerSolution
                  }
                  onMouseEnterHandlerContactus={
                    props.onMouseEnterHandlerContactus
                  }
                  onMouseLeaveHandlerContactus={props.onMouseLeaveHandlerContactus}
                />
                {/* <GetInTouchItem /> */}
                <LanguageItem
                  displayHeader={displayHeader}
                  isHover={props.isHover}
                  scroll={props.scroll}
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default navbaritems
