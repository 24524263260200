import React from "react"
import Backdrop from "../Backdrop/Backdrop"
// import GetInTouchItem from "../../header/navBarItem/getintouch.components"
import NavBarItemMobile from "../../header/navBarItemMobile/navBarMobile"
import logo from "../../../assets/img/media/Sunny.png"
import "./_SideDrawer.scss"
import { Link } from "gatsby"

const sidedrawer = props => {
  let attachedClasses = "Close"
  if (props.open) {
    attachedClasses = "Open"
  }

  return (
    <>
      <Backdrop show={props.open} clicked={props.closed} />
      <div className={`SideDrawer ${attachedClasses}`}>
        <div className="Logo" />
        <div className="groupGetInTouch">
          <div >
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="divCloseBtn" onClick={props.closed}>
            <div href="/" className="btn-close" />
          </div>
        </div>

        <nav>
          <NavBarItemMobile reset={props.open ? false : true} />
        </nav>
        {/* <div className="languageToggle">
          <p>Language</p>
        </div> */}
        {/* <div className="groupGetInTouch">
          <GetInTouchItem />
        </div> */}
      </div>
    </>
  )
}

export default sidedrawer
