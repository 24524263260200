import React from "react"
import { Link } from "gatsby"
import { withTranslation } from "react-i18next"

const ServicesMobileItem = props => {
  const { t } = props
  return (
    <ul className={`sub-menu ${props.show}`}>
      <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/services">
          {t("src.pages.footer.services.title")}
        </Link>
      </li>
      <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/services/mobile-development">
          {t("src.pages.footer.services.mobile")}
        </Link>
      </li>
      <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/services/web-applications-development">
          {t("src.pages.footer.services.web")}
        </Link>
      </li>
      <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/services/product-engineering">
          {t("src.pages.footer.services.product")}
        </Link>
      </li>
      <li className={`menu-item ${props.show}`}>
        <Link className="mobileA" to="/services/it-consulting">
          {t("src.pages.footer.services.consulting")}
        </Link>
      </li>
    </ul>
  )
}
export default withTranslation()(ServicesMobileItem)
