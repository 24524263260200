import React, { useState } from "react"
import { Link } from "gatsby"
import pic1 from "./../../../assets/img/media/header/3.1.png"
import pic2 from "./../../../assets/img/media/header/3.2.png"
// import pic3 from "./../../../assets/img/media/header/3.3.png"
import pic4 from "./../../../assets/img/media/header/3.4.png"
import pic5 from "./../../../assets/img/media/header/3-4.jpg"
import "./navBarItem.scss"
const industriesItem = props => {
  const [isHoverIndustries, setHoverIndustries] = useState("")
  let classHover = ["coverDropdown", ""] //in-view
  let classHover2 = ["dropdown-content", ""]
  if (props.isHoverIndustries === "Industries") {
    classHover = ["coverDropdown", "in-view"]
    classHover2 = ["dropdown-content", "in-view"]
  }

  // const onMouseEnterConstruction = () => {
  //   setHoverIndustries("Construction")
  // }
  // const onMouseLeaveConstruction = () => {
  //   setHoverIndustries("")
  // }
  // const onMouseEnterHealthcare = () => {
  //   setHoverIndustries("Healthcare")
  // }
  // const onMouseLeaveHealthcare = () => {
  //   setHoverIndustries("")
  // }
  // const onMouseEnterLogistics = () => {
  //   setHoverIndustries("Logistics")
  // }
  // const onMouseLeaveLogistics = () => {
  //   setHoverIndustries("")
  // }
  // const onMouseEnterRetails = () => {
  //   setHoverIndustries("Retails")
  // }
  // const onMouseLeaveRetails = () => {
  //   setHoverIndustries("")
  // }

  return (
    <div
      className={classHover.join(" ")}
      onMouseEnter={props.enter}
      onMouseLeave={props.leave}
    >
      <div className="container">
        <div className={classHover2.join(" ")}>
          <div className="coverElm">
            <div className="article-container">
              <div className="article__item">
                <div className="article__content">
                  <Link to="/clients" className="article__link">
                    <img
                      src={
                        isHoverIndustries === "Construction"
                          ? pic1
                          : isHoverIndustries === "Healthcare"
                          ? pic2
                          // : isHoverIndustries === "Logistics"
                          // ? pic3
                          : isHoverIndustries === "Retails"
                          ? pic4
                          : pic5
                      }
                      alt="Clients"
                      className="article__img"
                    />
                    <p className="article__description">
                      {isHoverIndustries === "Construction"
                        ? props.data[4].content
                        : isHoverIndustries === "Healthcare"
                        ? props.data[5].content
                        // : isHoverIndustries === "Logistics"
                        // ? props.data[12].content
                        : isHoverIndustries === "Retails"
                        ? props.data[13].content
                        : props.data[4].content}
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="ddElm3">
              <div className="headerSubTitle">
                <p>{props.data[4].subtitle}</p>
              </div>
              <div className="headerElm">
                {/* <Link
                  to="/industries/pharma"
                  onMouseEnter={onMouseEnterConstruction}
                  onMouseLeave={onMouseLeaveConstruction}
                >
                  Pharma
                </Link>
                <Link
                  to="/industries/smarthome"
                  onMouseEnter={onMouseEnterHealthcare}
                  onMouseLeave={onMouseLeaveHealthcare}
                >
                  {props.data[5].title}
                </Link> */}
                {/* <Link
                  to="/industries/logistics"
                  onMouseEnter={onMouseEnterLogistics}
                  onMouseLeave={onMouseLeaveLogistics}
                >
                  {props.data[12].title}
                </Link> */}
                {/* <Link
                  to="/industries/retail-ecommerce"
                  onMouseEnter={onMouseEnterRetails}
                  onMouseLeave={onMouseLeaveRetails}
                >
                  {props.data[13].title}
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default industriesItem
