import React, { useState } from "react"
import { Link } from "gatsby"
// import ClientsMobileItem from "./componentsMobile/clientsMobile.component"
import CompanyMobileItem from "./componentsMobile/companyMobile.component"
import SolutionsMobileItem from "./componentsMobile/solutionsMobile.component"
import IndustriesMobileItem from "./componentsMobile/industriesMobile.component"
import ServicesMobileItem from "./componentsMobile/servicesMobile.component"
import LanguageMobile from "./componentsMobile/languageMobile.component"
import { withTranslation } from "react-i18next"

const NavBarItemMobile = props => {
  const { t } = props
  const [toggleClass, setToggleClass] = useState("")

  // const addToggleClassClients = e => {
  //   e.preventDefault()
  //   setToggleClass(toggleClass === "Clients" ? "" : "Clients")
  // }
  const addToggleClassSolutions = e => {
    e.preventDefault()
    setToggleClass(toggleClass === "Solutions" ? "" : "Solutions")
  }
  const addToggleClassServices = e => {
    e.preventDefault()
    setToggleClass(toggleClass === "Services" ? "" : "Services")
  }
  const addToggleClassIndustries = e => {
    e.preventDefault()
    setToggleClass(toggleClass === "Industries" ? "" : "Industries")
  }
  const addToggleClassCompany = e => {
    e.preventDefault()
    setToggleClass(toggleClass === "Company" ? "" : "Company")
  }
  let resetToggleClass = false
  if (props.reset) {
    resetToggleClass = true
  }
  return (
    <>
      <div className="dropdownMB" style={{ cursor: "pointer" }}>
        <Link
          className={`nav-itemMB ${
            toggleClass === "Solutions" && !resetToggleClass ? "active" : ""
          }`}
          activeclassname="active-nav-itemMB"
          to="/"
          onClick={addToggleClassSolutions}
        >
          {t("src.pages.navbar.solutions")}

          <div className="rightArrowMobile"></div>
        </Link>
        <SolutionsMobileItem
          show={
            toggleClass === "Solutions" && !resetToggleClass ? "active" : ""
          }
        />
      </div>
      <div className="dropdownMB" style={{ cursor: "pointer" }}>
        <Link
          className={`nav-itemMB ${
            toggleClass === "Services" && !resetToggleClass ? "active" : ""
          }`}
          activeclassname="active-nav-itemMB"
          to="/services"
          onClick={addToggleClassServices}
        >
          {t("src.pages.navbar.services")}
        </Link>
        <ServicesMobileItem
          show={toggleClass === "Services" && !resetToggleClass ? "active" : ""}
        />
      </div>
      {/* <div className="dropdownMB" style={{ cursor: "pointer" }}>
        <Link
          className={`nav-itemMB ${
            toggleClass === "Industries" && !resetToggleClass ? "active" : ""
          }`}
          activeclassname="active-nav-itemMB"
          to="/industries"
          onClick={addToggleClassIndustries}
        >
          INDUSTRIES
        </Link>
        <IndustriesMobileItem
          show={
            toggleClass === "Industries" && !resetToggleClass ? "active" : ""
          }
        />
      </div> */}
      {/* <div className="dropdownMB" style={{ cursor: "pointer" }}>
        <Link
          className={`nav-itemMB ${
            toggleClass === "Clients" && !resetToggleClass ? "active" : ""
          }`}
          activeclassname="active-nav-itemMB"
          to="/clients"
          onClick={addToggleClassClients}
        >
          CLIENTS
        </Link>
        <ClientsMobileItem
          show={toggleClass === "Clients" && !resetToggleClass ? "active" : ""}
        />
      </div> */}
      <div className="dropdownMB" style={{ cursor: "pointer" }}>
        <Link
          className={`nav-itemMB ${
            toggleClass === "Company" && !resetToggleClass ? "active" : ""
          }`}
          activeclassname="active-nav-itemMB"
          to="/company"
          onClick={addToggleClassCompany}
        >
          {t("src.pages.navbar.company")}
        </Link>
        <CompanyMobileItem
          show={toggleClass === "Company" && !resetToggleClass ? "active" : ""}
        />
      </div>
      <div className="languageSilder">
        <p style={{ fontSize: 13, lineHeight: "18px" }}>
          {t("src.pages.sliderbar.lang")}
        </p>
        <LanguageMobile />
      </div>

      <div className="contactMB">
        <Link className="contact" to="/contactus">
          {t("src.pages.navbar.contact")}
        </Link>
      </div>
    </>
  )
}

export default withTranslation()(NavBarItemMobile)
