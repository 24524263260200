import React from "react"
import { Link } from "gatsby"
import { withTranslation } from "react-i18next"

const navbaritem = props => {
  const { displayHeader, t } = props
  let attachClasses2 = ["nav-item", ""]
  if (props.scroll) {
    attachClasses2 = ["nav-item", "scroll"]
  }
  if (displayHeader) {
    attachClasses2 = ["nav-item", "scroll"]
  }
  let activecolor = ""
  if (props.isHover) {
    activecolor = "activeColor"
  }
  return (
    <>
      <div
        className={`dropdown ${props.isHover === "Solution" ? "isHover" : ""}`}
        onMouseEnter={props.onMouseEnterHandlerSolution}
        style={{ cursor: "pointer" }}
      >
        <Link
          className={`${attachClasses2.join(" ")} ${
            props.toggleClass
          } ${activecolor}`}
          activeClassName="active-nav-item"
          to="/solutions"
        >
          {t("src.pages.navbar.solutions")}
        </Link>
      </div>
      <div
        className={`dropdown ${props.isHover === "Service" ? "isHover" : ""}`}
        onMouseEnter={props.onMouseEnterHandlerService}
      >
        <Link
          className={`${attachClasses2.join(" ")} ${
            props.toggleClass
          } ${activecolor}`}
          activeClassName="active-nav-item"
          to="/services"
        >
          {t("src.pages.navbar.services")}
        </Link>
      </div>

      {/* <div
        className={`dropdown ${
          props.isHover === "Industries" ? "isHover" : ""
        }`}
        onMouseEnter={props.onMouseEnterHandlerIndustries}
      >
        <Link
          className={`${attachClasses2.join(" ")} ${
            props.toggleClass
          } ${activecolor}`}
          activeClassName="active-nav-item"
          to="/industries"
        >
          INDUSTRIES
        </Link>
      </div> */}

      {/* <div
        className={`dropdown ${props.isHover === "Client" ? "isHover" : ""}`}
        onMouseEnter={props.onMouseEnterHandlerClient}
      >
        <Link
          className={`${attachClasses2.join(" ")} ${
            props.toggleClass
          } ${activecolor}`}
          activeClassName="active-nav-item"
          to="/clients"
        >
          CLIENTS
        </Link>
      </div> */}
      <div
        className={`dropdown ${props.isHover === "Company" ? "isHover" : ""}`}
        onMouseEnter={props.onMouseEnterHandlerCompany}
      >
        <Link
          className={`${attachClasses2.join(" ")} ${
            props.toggleClass
          } ${activecolor}`}
          activeClassName="active-nav-item"
          to="/company"
        >
          {t("src.pages.navbar.company")}
        </Link>
      </div>
      <div
        className={`dropdown ${props.isHover === "Contactus" ? "isHover" : ""}`}
        onMouseEnter={props.onMouseEnterHandlerContactus}
        onMouseLeave={props.onMouseLeaveHandlerContactus}
      >
        <div>
          <Link
            className={`${attachClasses2.join(" ")} ${
              props.toggleClass
            } ${activecolor}`}
            activeClassName="active-nav-item"
            to="/contactus"
          >
            {t("src.pages.navbar.contact")}
          </Link>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(navbaritem)
