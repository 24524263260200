import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import pic1 from "./../../../assets/img/media/header/2.1.png"
import pic2 from "./../../../assets/img/media/header/2.2.png"
import pic3 from "./../../../assets/img/media/header/2.3.png"
import pic4 from "./../../../assets/img/media/header/2.4.png"
import pic5 from "./../../../assets/img/media/header/2-4.jpg"
import { Link } from "gatsby"
import "./navBarItem.scss"
const serviceItem = props => {
  const { t } = props

  const [isHoverServices, setHoverServices] = useState("")

  let classHover = ["coverDropdown", ""] //in-view

  let classHover2 = ["dropdown-content", ""]
  if (props.isHoverService === "Service") {
    classHover = ["coverDropdown", "in-view"]
    classHover2 = ["dropdown-content", "in-view"]
  }

  const onMouseEnterMobile = () => {
    setHoverServices("Mobile")
  }
  const onMouseLeaveMobile = () => {
    setHoverServices("")
  }
  const onMouseEnterWebApp = () => {
    setHoverServices("WebApp")
  }
  const onMouseLeaveWebApp = () => {
    setHoverServices("")
  }
  const onMouseEnterProduct = () => {
    setHoverServices("Product")
  }
  const onMouseLeaveProduct = () => {
    setHoverServices("")
  }
  const onMouseEnterIT = () => {
    setHoverServices("IT")
  }
  const onMouseLeaveIT = () => {
    setHoverServices("")
  }

  return (
    <div
      className={classHover.join(" ")}
      onMouseEnter={props.enter}
      onMouseLeave={props.leave}
    >
      <div className="container">
        <div className={classHover2.join(" ")}>
          <div className="coverElm">
            <div className="article-container">
              <div className="article__item">
                <div className="article__content">
                  <Link to="/clients" className="article__link">
                    <img
                      src={
                        isHoverServices === "Mobile"
                          ? pic1
                          : isHoverServices === "WebApp"
                          ? pic2
                          : isHoverServices === "Product"
                          ? pic3
                          : isHoverServices === "IT"
                          ? pic4
                          : pic5
                      }
                      alt="Clients"
                      className="article__img"
                    />
                    <p className="article__description">
                      {isHoverServices === "Mobile"
                        ? t("src.pages.header.services.mobileContent")
                        : isHoverServices === "WebApp"
                        ? t("src.pages.header.services.webAppContent")
                        : isHoverServices === "Product"
                        ? t("src.pages.header.services.productContent")
                        : isHoverServices === "IT"
                        ? t("src.pages.header.services.itContent")
                        : t("src.pages.header.services.mobileContent")}
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="ddElm3">
              <div className="headerSubTitle">
                <p>{t("src.pages.footer.services.title")}</p>
              </div>
              <div className="headerElm">
                <Link
                  to="/services/mobile-development"
                  onMouseEnter={onMouseEnterMobile}
                  onMouseLeave={onMouseLeaveMobile}
                >
                  {t("src.pages.footer.services.mobile")}
                </Link>
                <Link
                  to="/services/web-applications-development"
                  onMouseEnter={onMouseEnterWebApp}
                  onMouseLeave={onMouseLeaveWebApp}
                >
                  {t("src.pages.footer.services.web")}
                </Link>
                <Link
                  to="/services/product-engineering"
                  onMouseEnter={onMouseEnterProduct}
                  onMouseLeave={onMouseLeaveProduct}
                >
                  {t("src.pages.footer.services.product")}
                </Link>
                <Link
                  to="/services/it-consulting"
                  onMouseEnter={onMouseEnterIT}
                  onMouseLeave={onMouseLeaveIT}
                >
                  {t("src.pages.footer.services.consulting")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default withTranslation()(serviceItem)
