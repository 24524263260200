import React, { useState } from "react"

import "./header.scss"
import "./../../styles/index.scss"
import SolutionItem from "./navBarItem/solutionItem.component"
import ServiceItem from "./navBarItem/serviceItem.component"
import IndustriesItem from "./navBarItem/industriesItem.component"
import ClientItem from "./navBarItem/clientItem.component"
import CompanyItem from "./navBarItem/companyItem.component"
import NavbarItems from "./navBarItem/navbarItems"
import { DATA } from "../../data/staticData"

const Header = props => {
  const { displayHeader } = props
  const [active, setActive] = useState(false)
  const [isHover, setHover] = useState("")
  const [toggleClass, setToggleClass] = useState("")
  const [animation, setAnimate] = useState("")
  const onMouseEnterHandlerSolution = () => {
    setHover("Solution")
  }
  const onMouseLeaveHandlerSolution = () => {
    setHover("")
  }
  const onMouseEnterHandlerService = () => {
    setHover("Service")
  }
  const onMouseLeaveHandlerService = () => {
    setHover("")
  }
  const onMouseEnterHandlerIndustries = () => {
    setHover("Industries")
  }
  const onMouseLeaveHandlerIndustries = () => {
    setHover("")
  }
  const onMouseEnterHandlerResources = () => {
    setHover("Resources")
  }
  const onMouseLeaveHandlerResources = () => {
    setHover("")
  }
  const onMouseEnterHandlerClient = () => {
    setHover("Client")
  }
  const onMouseLeaveHandlerClient = () => {
    setHover("")
  }
  const onMouseEnterHandlerCompany = () => {
    setHover("Company")
  }
  const onMouseLeaveHandlerCompany = () => {
    setHover("")
  }
  const onMouseEnterHandlerContactus = () => {
    setHover("Contactus")
  }
  const onMouseLeaveHandlerContactus = () => {
    setHover("")
  }
  const addResponsiveClass = () => {
    setActive(!active)
    setToggleClass(toggleClass === "responsive" ? "" : "responsive")
    setAnimate(animation === "animate" ? "" : "animate")
  }
  const data = DATA
  let attachClasses = ["header", ""]
  if (props.scroll) {
    attachClasses = ["header", "scroll"]
  }
  if (isHover) {
    attachClasses = ["header", "scroll"]
  }
  return (
    <header className={attachClasses.join(" ")}>
      <NavbarItems
        displayHeader={displayHeader}
        drawerToggleClicked={props.drawerToggleClicked}
        toggleClasss={props.toggleClass}
        scroll={props.scroll}
        isHover={isHover}
        toggleClass={toggleClass}
        animation={animation}
        addResponsiveClass={addResponsiveClass}
        onMouseEnterHandlerSolution={onMouseEnterHandlerSolution}
        onMouseEnterHandlerClient={onMouseEnterHandlerClient}
        onMouseEnterHandlerCompany={onMouseEnterHandlerCompany}
        onMouseEnterHandlerIndustries={onMouseEnterHandlerIndustries}
        onMouseEnterHandlerResources={onMouseEnterHandlerResources}
        onMouseEnterHandlerService={onMouseEnterHandlerService}
        onMouseEnterHandlerContactus={onMouseEnterHandlerContactus}
        onMouseLeaveHandlerContactus={onMouseLeaveHandlerContactus}
      />
      <SolutionItem
        enter={onMouseEnterHandlerSolution}
        leave={onMouseLeaveHandlerSolution}
        data={data}
        isHoverSolution={isHover}
      />
      <ServiceItem
        enter={onMouseEnterHandlerService}
        leave={onMouseLeaveHandlerService}
        // data={data}
        isHoverService={isHover}
      />
      <IndustriesItem
        enter={onMouseEnterHandlerIndustries}
        leave={onMouseLeaveHandlerIndustries}
        data={data}
        isHoverIndustries={isHover}
      />
      <ClientItem
        enter={onMouseEnterHandlerClient}
        leave={onMouseLeaveHandlerClient}
        data={data}
        isHoverClient={isHover}
      />
      <CompanyItem
        enter={onMouseEnterHandlerCompany}
        leave={onMouseLeaveHandlerCompany}
        // data={data}
        isHoverCompany={isHover}
      />
    </header>
  )
}

export default Header
