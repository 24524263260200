import React from "react"
import "./_DrawerToggle.scss"

const drawerToggle = props => {
  return (
    <div className='item-drawerToggle'>
      <div onClick={props.clicked} className="DrawerToggleContainer">
        <div className="bar" />
      </div>
    </div>
  )
}

export default drawerToggle
