import React, { Component } from "react"
//import { officesHome } from "../../data/staticData";
import FooterTitle from "./footerTitle/footerTitle.component"
import FooterCopyRight from "./footerCopyright/footerCopyRight.component"
import FooterMobile from './footerMobile/footerMobile.component'
import "./footer.scss"
class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <FooterMobile />
          <FooterTitle />
          <FooterCopyRight />
        </div>
      </footer>
    )
  }
}

export default Footer
