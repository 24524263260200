import React, { Component } from "react"
import Header from "../header/header.component"
import Footer from "../footer/footer.component"
import SideDrawer from "./../UI/SideDrawer/SideDrawer.component"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n"

import "./_layout.scss"
class Layout extends Component {
  state = {
    scroll: false,
    scrolling: false,
    showSideDrawer: false,
    toggleClass: "",
    hideHeader: false,
  }
  SideDrawerClosedHandler = () => {
    this.setState({
      showSideDrawer: false,
      toggleClass: ""
    })
  }
  sideDrawerToggleHandler = () => {
    this.setState(prevState => {
      return {
        showSideDrawer: !prevState.showSideDrawer,
        toggleClass: this.state.toggleClass === "change" ? "" : "change"
      }
    })
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = e => {
    window.scrollY === 0 || window.scrollY > 500
      ? this.setState({ hideHeader: false })
      : this.setState({ hideHeader: true })
    window.scrollY > 500
      ? this.setState({ scrolling: true })
      : this.setState({ scrolling: false })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    const {
      props: { displayHeader }
    } = this
    let headerClass = this.state.hideHeader
      ? "hideHeaderClass"
      : "showHeaderClass"
    return (
      <I18nextProvider i18n={i18n}>
        <div>
          <div className={headerClass}>
            <Header
              displayHeader={displayHeader}
              scroll={this.state.scrolling}
              drawerToggleClicked={this.sideDrawerToggleHandler}
              toggleClass={this.state.toggleClass}
            />
          </div>

          <SideDrawer
            closed={this.SideDrawerClosedHandler}
            open={this.state.showSideDrawer}
          />

          <div style={{ backgroundColor: "rgba(233, 232, 233, 0)" }}>
            {this.props.children}
            <Footer className="container-fluid" />
          </div>
        </div>
      </I18nextProvider>
    )
  }
}

export default Layout
