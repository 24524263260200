import React, { useState } from "react"
import { Link } from "gatsby"
import { withTranslation } from "react-i18next"
import pic1 from "./../../../assets/img/media/header/6.jpg"
import pic2 from "./../../../assets/img/media/header/7.png"
import pic3 from "./../../../assets/img/media/header/8.png"
import pic4 from "./../../../assets/img/media/header/9.png"
import pic5 from "./../../../assets/img/media/header/10.png"

import pic6 from "./../../../assets/img/media/header/11.png"
import pic7 from "./../../../assets/img/media/header/11.1.png"
import pic8 from "./../../../assets/img/media/header/12.png"

import "./navBarItem.scss"
const resourcesItem = props => {
  const { t } = props
  const [isHoverCompany, setHoverCompany] = useState("")
  let classHover = ["coverDropdown", ""] //in-view

  let classHover2 = ["dropdown-content", ""]
  if (props.isHoverCompany === "Company") {
    classHover = ["coverDropdown", "in-view"]
    classHover2 = ["dropdown-content", "in-view"]
  }

  // const elemArr = [10, 14, 15, 11, 16]
  // const elemArr2 = [21, 7, 6]

  const onMouseEnterAbout = () => {
    setHoverCompany("About")
  }
  const onMouseLeaveAbout = () => {
    setHoverCompany("")
  }
  // const onMouseEnterPartners = () => {
  //   setHoverCompany("Partners")
  // }
  // const onMouseLeavePartners = () => {
  //   setHoverCompany("")
  // }
  // const onMouseEnterTeam = () => {
  //   setHoverCompany("Team")
  // }
  // const onMouseLeaveTeam = () => {
  //   setHoverCompany("")
  // }
  // const onMouseEnterCareer = () => {
  //   setHoverCompany("Career")
  // }
  const onMouseLeaveCareer = () => {
    setHoverCompany("")
  }
  const onMouseEnterContact = () => {
    setHoverCompany("Contact")
  }
  const onMouseLeaveContact = () => {
    setHoverCompany("")
  }

  const onMouseEnterBlog = () => {
    setHoverCompany("Blog")
  }
  const onMouseLeaveBlog = () => {
    setHoverCompany("")
  }
  const onMouseEnterNews = () => {
    setHoverCompany("News")
  }
  const onMouseLeaveNews = () => {
    setHoverCompany("")
  }
  const onMouseEnterInsights = () => {
    setHoverCompany("Insights")
  }
  const onMouseLeaveInsights = () => {
    setHoverCompany("")
  }

  return (
    <div
      className={classHover.join(" ")}
      onMouseEnter={props.enter}
      onMouseLeave={props.leave}
    >
      <div className="container">
        <div className={classHover2.join(" ")}>
          <div className="coverElm containter">
            <div className="article-container">
              <div className="article__item">
                <div className="article__content">
                  <Link to="/company" className="article__link">
                    <img
                      src={
                        isHoverCompany === "About"
                          ? pic1
                          : // : isHoverCompany === "Partners"
                          // ? pic2
                          // isHoverCompany === "Team"
                          // ? pic3
                          // : isHoverCompany === "Career"
                          // ? pic4
                          isHoverCompany === "Contact"
                          ? pic5
                          : isHoverCompany === "Blog"
                          ? pic6
                          : isHoverCompany === "News"
                          ? pic7
                          : isHoverCompany === "Insights"
                          ? pic8
                          : pic1
                      }
                      alt="Clients"
                      className="article__img"
                    />
                    <p className="article__description">
                      {isHoverCompany === "About"
                        ? t("src.pages.header.company.aboutContent")
                        : // : isHoverCompany === "Partners"
                        // ? props.data[14].content
                        // isHoverCompany === "Team"
                        // ? props.data[15].content
                        // : isHoverCompany === "Career"
                        // ? props.data[11].content
                        isHoverCompany === "Contact"
                        ? t("src.pages.header.company.contactContent")
                        : isHoverCompany === "Blog"
                        ? t("src.pages.header.company.blogContent")
                        : isHoverCompany === "News"
                        ? t("src.pages.header.company.newContent")
                        : isHoverCompany === "Insights"
                        ? t("src.pages.header.company.insightsContent")
                        : t("src.pages.header.company.aboutContent")}
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="ddElm3 ddelmOne">
              <div className="headerSubTitle">
                <p>{t("src.pages.footer.company.title")}</p>
              </div>
              <div className="headerElm">
                <Link
                  to="/company"
                  onMouseEnter={onMouseEnterAbout}
                  onMouseLeave={onMouseLeaveAbout}
                >
                  {t("src.pages.footer.company.about")}
                </Link>
                {/* <Link
                  to="/"
                  onMouseEnter={onMouseEnterPartners}
                  onMouseLeave={onMouseLeavePartners}
                >
                  {props.data[14].title}
                </Link> */}
                {/* <Link
                  to="/company/team"
                  onMouseEnter={onMouseEnterTeam}
                  onMouseLeave={onMouseLeaveTeam}
                >
                  {props.data[15].title}
                </Link>
                <Link
                  to="/company/careers"
                  onMouseEnter={onMouseEnterCareer}
                  onMouseLeave={onMouseLeaveCareer}
                >
                  {props.data[11].title}
                </Link> */}
                <Link
                  to="/company/contact"
                  onMouseEnter={onMouseEnterContact}
                  onMouseLeave={onMouseLeaveContact}
                >
                  {t("src.pages.footer.contact.title")}
                </Link>
              </div>
            </div>
            {/* <div className="ddElm3 ddelmTwo">
              <div className="headerSubTitle">
                <p>{props.data[21].subtitle}</p>
              </div>
              <div className="headerElm">
                <a
                  href="http://blog.acexis.com/"
                  target="_blank"
                  onMouseEnter={onMouseEnterBlog}
                  onMouseLeave={onMouseLeaveBlog}
                >
                  {props.data[21].title}
                </a> */}
            {/* <Link
                  to="/company/news"
                  onMouseEnter={onMouseEnterNews}
                  onMouseLeave={onMouseLeaveNews}
                >
                  {props.data[7].title}
                </Link> */}
            {/* <Link
                  to="/company/insights"
                  onMouseEnter={onMouseEnterInsights}
                  onMouseLeave={onMouseLeaveInsights}
                >
                  {props.data[6].title}
                </Link> */}
            {/* </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
export default withTranslation()(resourcesItem)
