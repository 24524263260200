import React, { useState } from "react"
import pic1 from "./../../../assets/img/media/header/5.jpg"
import pic2 from "./../../../assets/img/media/header/4.png"
import "./navBarItem.scss"
import { Link } from "gatsby"

const clientItem = props => {
  const [isHoverClient, setHoverClient] = useState("")
  let classHover = ["coverDropdown", ""] //in-view

  let classHover2 = ["dropdown-content", ""]
  if (props.isHoverClient === "Client") {
    classHover = ["coverDropdown", "in-view"]
    classHover2 = ["dropdown-content", "in-view"]
  }

  const onMouseEnterClients = () => {
    setHoverClient("Clients")
  }
  const onMouseLeaveClients = () => {
    setHoverClient("")
  }
  const onMouseEnterStudies = () => {
    setHoverClient("Studies")
  }
  const onMouseLeaveStudies = () => {
    setHoverClient("")
  }

  return (
    <div
      className={classHover.join(" ")}
      onMouseEnter={props.enter}
      onMouseLeave={props.leave}
    >
      <div className="container">
        <div className={classHover2.join(" ")}>
          <div className="coverElm containter">
            <div className="article-container">
              <div className="article__item">
                <div className="article__content">
                  <Link to="/clients" className="article__link">
                    <img
                      src={
                        isHoverClient === "Clients"
                          ? pic1
                          : isHoverClient === "Studies"
                          ? pic2
                          : pic1
                      }
                      alt="Clients"
                      className="article__img"
                    />
                    <p className="article__description">
                      {isHoverClient === "Clients"
                        ? props.data[8].content
                        : isHoverClient === "Studies"
                        ? props.data[9].content
                        : props.data[8].content}
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="ddElm3">
              <div className="headerSubTitle">
                <p>{props.data[8].subtitle}</p>
              </div>
              <div className="headerElm">
                <Link
                  to="/clients"
                  onMouseEnter={onMouseEnterClients}
                  onMouseLeave={onMouseLeaveClients}
                >
                  {props.data[8].title}
                </Link>
                <Link
                  to="/clients/case-studies"
                  onMouseEnter={onMouseEnterStudies}
                  onMouseLeave={onMouseLeaveStudies}
                >
                  {props.data[9].title}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default clientItem
