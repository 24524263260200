import React, { useState } from "react"
import { withTranslation } from "react-i18next"

const LanguageItem = props => {
  const { i18n, displayHeader, isHover, scroll, t } = props
  const [lang, setlang] = useState(
    i18n.language === "en" ? "ENGLISH" : "TIẾNG VIỆT"
  )
  const [open, setopen] = useState(false)
  let attachClasses = ""
  let attachClasses2 = ""
  let attachClasses3 = ""
  const openDropdown = () => {
    if (!open) setopen(true)
    else if (open) setopen(true)
  }
  const closeDropdown = () => {
    setopen(false)
  }
  const openDropdownUL = () => {
    if (open) setopen(true)
    else setopen(!open)
  }
  if (displayHeader) {
    attachClasses = "active-color"
  }
  if (isHover) {
    attachClasses2 = "active-hover"
  }
  if (scroll) {
    attachClasses3 = "active-scroll"
  }
  function Changelanguage(select) {
    if (select === "en") {
      setlang("ENGLISH")
    } else {
      setlang("TIẾNG VIỆT")
    }
    i18n.changeLanguage(select)
  }
  const keydown = () => {}

  return (
    <div
      className={`language ${
        open ? "open" : ""
      } ${attachClasses} ${attachClasses2} ${attachClasses3}`}
      onMouseLeave={closeDropdown}
    >
      {/* <select onChange={Changelanguage} value={i18n.language}>
        <option value="en">
          ENGLISH
        </option>
        <option value="vi">
          VIETNAMESE
        </option>
      </select> */}
      <div
        onBlur={openDropdown}
        role="button"
        tabIndex={0}
        // onClick={openDropdown}
        onMouseEnter={openDropdown}
        onKeyDown={keydown}
        className="current-language"
        style={{ display: "flex", alignItems: "center" }}
      >
        <p>{lang}</p>
      </div>
      {/* <ul className="dropdown-menu">
        <li>
          <button type='button' onMouseDown={Changelanguage.bind(this,"en")}>
            ENGLISH
          </button>
        </li>
        <li>
          <button type='button' onMouseDown={Changelanguage.bind(this,"vi")}>
            VIETNAMESE
          </button>
        </li>
      </ul> */}
      <ul
        className={`dropdown-menu ${open ? `show` : "hide"}`}
        onMouseEnter={openDropdownUL}
      >
        {i18n.language === "en" ? (
          <li>
            <button type="button" onMouseDown={Changelanguage.bind(this, "vi")}>
              {t("src.pages.sliderbar.vietnam")}
            </button>
          </li>
        ) : (
          <li>
            <button type="button" onMouseDown={Changelanguage.bind(this, "en")}>
              {t("src.pages.sliderbar.english")}
            </button>
          </li>
        )}
      </ul>
    </div>
  )
}

export default withTranslation()(LanguageItem)
