import React from "react"
import "./footerTitle.scss"
import { officesHome } from "../../../data/staticData"
import { Link } from "gatsby"
import { withTranslation } from "react-i18next"

const FooterTitle = props => {
  const { t } = props
  return (
    <div className="footer__flexbox">
      <ul className="footer__menu">
        <li className="has-dropdown">
          <Link to="/solutions" className="has-dropdownAtitle">
            {t("src.pages.footer.solutions.title")}
          </Link>
          <ul className="sub-menu">
            {/* <li>
              <a href="https://mdconference.acexis.com/" target="_blank">
                {t("src.pages.footer.solutions.webinar")}
              </a>
            </li> */}
            <li>
              <Link to="/solutions/pharma">
                {t("src.pages.footer.solutions.pharma")}
              </Link>
            </li>
            {/* <li>
              <Link to="/solutions/smarthome">
                {t("src.pages.footer.solutions.smartHome")}
              </Link>
            </li>
            <li>
              <Link to="/solutions/digitalhealth">
                {t("src.pages.footer.solutions.digital")}
              </Link>
            </li>
            <li>
              <Link to="/solutions/spirometer">
                {t("src.pages.footer.solutions.spirometer")}
              </Link>
            </li> */}
            <li>
              <Link to="/solutions/eventapp">
                {t("src.pages.footer.solutions.eventApp")}
              </Link>
            </li>
            <li>
              <Link to="/solutions/erp">
                {t("src.pages.footer.solutions.erp")}
              </Link>
            </li>
          </ul>
        </li>
        <li className="has-dropdown">
          <Link to="/services" className="has-dropdownAtitle">
            {t("src.pages.footer.services.title")}
          </Link>
          <ul className="sub-menu">
            <li>
              <Link to="/services/mobile-development">
                {t("src.pages.footer.services.mobile")}
              </Link>
            </li>
            <li>
              <Link to="/services/web-applications-development">
                {t("src.pages.footer.services.web")}
              </Link>
            </li>
            <li>
              <Link to="/services/product-engineering">
                {t("src.pages.footer.services.product")}
              </Link>
            </li>
            <li>
              <Link to="/services/it-consulting">
                {t("src.pages.footer.services.consulting")}
              </Link>
            </li>
          </ul>
        </li>

        <li className="has-dropdown">
          <Link to="/company" className="has-dropdownAtitle">
            {t("src.pages.footer.company.title")}
          </Link>
          <ul className="sub-menu">
            <li>
              <Link to="/company">{t("src.pages.footer.company.about")}</Link>
            </li>
          </ul>
        </li>
      </ul>{" "}
      <div className="footer__about">
        <div className="footer__address">
          <Link to="/contactus" className="footer__heading">
            {t("src.pages.footer.contact.title")}
          </Link>
          <div className="footer__office">
            <div className="footer__phone">{officesHome[0].phone1}</div>
            <div className="footer__phone">Email: <a>info@sunny.net.vn</a></div>
            <div className="footer__address">{officesHome[0].address1} </div>
          </div>
        </div>{" "}
      </div>
      {/* Footer Mobile */}
    </div>
  )
}

export default withTranslation()(FooterTitle)
