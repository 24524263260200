export const DATA = [
  {
    id: 0,
    title: "Internet Of Thing",
    subtitle:'Solutions',
    content: ""
      // "Acexis team provides " +
      // "comprehensive IoT software development expertise to help you build" +
      // "a full-scale ecosystem around your Internet of Things solution."
  },
  {
    id: 1,
    title: "Blockchain Technologies",
    content:
      "Introduce the highest level of security and automate your operations with our blockchain solutions."
  },
  {
    id: 2,
    title: "Product Engineering",
    content:
      "Enterprise software development with all-embracing 10-year expertise and the latest tech."
  },
  {
    id: 3,
    title: "Mobile App Development",
    subtitle:'Services',
    content:
      "Building solid apps faster by using a mobile platform and custom development at once."
  },
  {
    id: 4,
    title: "Construction",
    subtitle:'Industries',
    content:
      "Comprehensive digital transformation for the construction industry with AR, mobile, and scalable enterprise software."
  },
  {
    id: 5,
    title: "Smarthome",
    content:
      "Practical cross-platform software that aids all the sides of the healthcare process."
  },
  {
    id: 6,
    title: "Insights",
    content:
      "We 've' " +
      "provided tailored learning and development solutions that help businesses get the very best from their people at work."
  },
  {
    id: 7,
    title: "News",
    content:
      "The most important technology news, developments and trends with insightful analysis and commentary."
  },
  {
    id: 8,
    subtitle:"Clients",
    title: "Our Clients",
    content:
      "Acexis a Vietnam-based smart home startup, envisioned an intelligent system..."
  },
  {
    id: 9,
    title: "Case Studies",
    content:
      "A publishing platform helps establish a consistent brand voice online ..."
  },
  {
    id: 10,
    title: "About Us",
    subtitle:'Company',
    content: "Learn more about acexis in numbers, products, and awards."
  },
  {
    id: 11,
    title: "Careers",
    content:
      "See the latest available career opportunities in our offices around the world."
  },
  {
    id: 12,
    title: "Logistics",
    content:
      ""
  },
  {
    id: 13,
    title: "Retail & Ecommerce",
    content:
      ""
  },
  {
    id: 14,
    title: "Partners",
    content:
      ""
  },
  {
    id: 15,
    title: "Team",
    content:
      ""
  },
  {
    id: 16,
    title: "Contact",
    content:
      ""
  },
  {
    id: 17,
    title: "Web Applications Development",
    content:
      ""
  },
  {
    id: 18,
    title: "IT consulting",
    content:
      ""
  },
  {
    id: 19,
    title: "Cloud Computing",
    content:
      ""
  },
  {
    id: 20,
    title: "Artificial Intelligent",
    content:
      ""
  },
  {
    id: 21,
    title: "Blog",
    subtitle:'Resources',
    content:
      ""
  },
  {
    id: 22,
    title: "Spirometer",
    subtitle:'Spirometer',
    content:
      ""
  },
  

];

//HomepageTitle
export const dataHomepageTitle = [
  {
    id: 0,
    text1: "Enterprise Software Development",
    text2:
      "Leverage our two-year expertise to create a solid software foundation for your business."
  },
  {
    id: 1,
    text1: "Mobile App Development",
    text2:
      "We design and develop the android and iPhone application for your IoT, " +
      "product with rich feature UI that could empower enterprises with next " +
      "generation technology by our iot mobile app development services."
  },
  {
    id: 2,
    text1: "Blockchain Technologies",
    text2:
      "Introduce the highest level of security and automate your operations with our blockchain solutions."
  },
  {
    id: 3,
    text1: "CUSTOM IoT DEVELOPMENT SERVICES",
    text2:
      "Build secure enterprise Internet of Things (IoT) solutions that will help your business gather Big Data, " +
      "optimise operations, and unlock a new level of efficiency."
  },
  {
    id: 4,
    text1: "Cloud Computing",
    text2:
      "Use cloud computing solutions to create a scalable, flexible, and connected enterprise environment."
  },
  {
    id: 5,
    text1: "Artificial Intelligence",
    text2:
      "The central goal of AI is to provide a set of algorithms and " +
      "techniques that can be used to solve problems that humans" +
      "perform intuitively and near automatically, but are otherwise very challenging for computers."
  }
]

//Homepage Offer

export const DATASOLUTIONS = [
  {
    id: 0,
    title: "Blockchain Technologies",
    content:
      "Introduce the highest level of security and automate your operations with our blockchain solutions."
  },
  {
    id: 1,
    title: "Cloud Computing",
    content:
      "Use cloud computing solutions to create a scalable," +
      "flexible, and connected enterprise environment."
  },
  {
    id: 2,
    title: "Artificial Intelligence",
    content:
      "Custom AI-based solutions like machine and deep learning will help you automate any operation."
  },
  {
    id: 3,
    title: "Internet of Things",
    content:
      "Secure IoT solutions will let you gather Big Data, optimise key processes, and improve decision-making."
  }
];

export const DATASERVICES = [
  {
    id: 0,
    title: "Web Applications Development",
    content:
      "Create complex enterprise software, ensure reliable software integration, modernise your legacy system."
  },
  {
    id: 1,
    title: "Mobile Applications Development",
    content:
      "Create an impactful mobile app that fits your brand and industry within a shorter time frame."
  },
  {
    id: 2,
    title: "IT Consulting",
    content:
      "Trust our top minds to eliminate workflow pain points," +
      "implement new tech, and consolidate app portfolios. "
  },
  {
    id: 3,
    title: "Product Engineering",
    content:
      "We transform your vision into victorious real world products by better communication, idea sharing."
  }
];

export const DATAINDUSTRIES = [
  {
    id: 0,
    title: "Construction",
    content:
      "Advanced software solutions that take Construction into the Digital Era."
  },
  {
    id: 1,
    title: "Healthcare",
    content:
      "Patient-friendly software that empowers healthcare industry workers."
  },
  {
    id: 2,
    title: "Retail & eCommerce",
    content: "Engaging and exciting software solutions for modern retail. "
  },
  {
    id: 3,
    title: "Logistics",
    content:
      "Use all the latest tech available to make your logistics run like clockwork."
  }
];


//Homepage Offices
export const officesHome = [
  {
    id: 0,
    title: "Our Offices",
    content:
      "Acexis operates in multiple offices and development center across Vietnam. " +
      "Reach out to us for software development and services in your region.",
    address1: "1196 3/2 WARD 8, DISTRICT 11, HCM CITY",
    phone1: "+84 901 919 757",
    address2: "154 BA LANG, NHA TRANG CITY, KHANH HOA PROVINCE",
    phone2: "+84 901 919 757",
    year: "2019 Acexis Vietnam",
    address3: "168 LY THAI TONG, HOA MINH WARD, LIEN CHIEU DISTRICT",
    phone3: "+84 2363 3 86 3336"
  }
];

//Homepage About
export const aboutHome = {
  title: "Who we are",
  content:
    "Found in 2018 as a team of cross-industries experts, system integrators and application developers, Acexis is now a cross-department team of mobile, web, data and embedded system specialists with extensive experience on the business software development market, a reliable information technology partner for Vietnam SME and MNC brands. We know what defines impactful, wide-ranging solutions powered by the latest technologies across all industries. With passion and exhaustive real-life cross-functional skills and the ability of our software engineers, we bring your ideas to life, and create stunning innovated applications for your business.",
  btn: "Talk to our team"
};


//Homepage Our Clients
export const clientHome = [
  {
    id: 0,
    title: "Meganet",
    content:
      "Meganet is Vietnam #1 company in Wireless Networks, Wireless Routers," +
      "Wi-Fi Marketing and Network Appliances"
  },
  {
    id: 1,
    title: "Haivanship",
    content: `Haivanship own fleet of tugboats, barges, buoys and other facilities…
      we are providing services asf:Harbor Tug, Towage, Marine Salvage, Oil Spill 
      Response, Water way transportation, Port Shipping Agency`
  },
  {
    id: 2,
    title: "Luong Quoi",
    content: `Luong Quoi Coconut Processing Co.,
     Ltd has continuously promoted the production line, improving 
     the quality of products with the desire to bring the best products to consumers`
  },
  {
    id: 3,
    title: "Thanh Hoai",
    content: "One of the largest pharmacy in Nha Trang city"
  },
  {
    id: 4,
    title: "Nha Trang University",
    content: `Nha Trang University (NTU) which is located in reputedly
     the most beautiful coastal city in Vietnam. NTU is one of 50 public 
     universities in Vietnam with more than 50 years of history and development.`
  },
];
